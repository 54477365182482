import axios, { AxiosRequestConfig } from "axios";
import { create } from "apisauce";
import { isInLogoutUrl, isInLoginUrl, isInHomeUrl, ENVIRONMENT_SYSTEM } from "../util";
import { getTokenFromLocalStorage } from '../util'
console.log('ENVIRONMENT', ENVIRONMENT_SYSTEM())
const api = axios.create({ baseURL: ENVIRONMENT_SYSTEM().API });

api.interceptors.response.use(undefined, (error: any) => {
  if (error?.response?.status && error?.response?.status === 500) {
    error.response.data = { message: "Internal Server Error" };
    // Sentry.captureException(error);
  }

  // Intercepta o response e se to toke estiver expirado dar um feedback para o usuário
  if (
    error?.response?.status &&
    error?.response?.status === 498 &&
    !isInLogoutUrl() &&
    !isInHomeUrl() &&
    !isInLoginUrl()
  ) {
    alert('"Sua sessão de login expirou. Por favor, faça login novamente!"');
    window.location.href = "../../../../../../logout";
    return;
  }
  return Promise.reject(error);
});

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = getTokenFromLocalStorage()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// const naviMonitor = response => console.log('hey!  listen! ', response);
// api.addMonitor(naviMonitor);
// import Reactotron from 'reactotron-react-native' // or use this for mobile

// Apisauce has a feature where you can attach a handler to watch
// all requests/response flowing through your api.  You can hook this up:

export default api;
