import React from "react";
import { store, persistor } from "store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "routes";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalStyles from "./styles/GlobalStyles";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <></>
    // <QueryClientProvider client={queryClient}>
    //   <Provider store={store}>
    //     <PersistGate loading={null} persistor={persistor}>
    //       <Router>
    //         <GlobalStyles />
    //         <Routes />
    //       </Router>
    //     </PersistGate>
    //   </Provider>
    // </QueryClientProvider>
  );
};

export default App;
