// import { getApiType } from "../util";
export type ENVIRONMENT = "PROD" | "DEV" | "HML";
export interface ENVIRONMENTS {
  ENVIRONMENT: ENVIRONMENT;
  URL: string;
  API: string;
}

export const APP_INFO = {
  NAME: "BAHIA SEM FOME",
  SLOGAN: "Controle De Doações Secretaria da Educação",
  TITLE: "",
  DESCRIPTION: "",
  BASE_URL_DEV: "http://doacaoapidev.sec.ba.gov.br/api",
  BASE_URL_HML: "http://doacaoapihml.sec.ba.gov.br/api",
  BASE_URL_PROD: "http://doacaoapi.sec.ba.gov.br/api",
  FRONT_URL_DEV: "http://doacaodev.sec.ba.gov.br/",
  FRONT_URL_HML: "http://doacaohml.sec.ba.gov.br/",
  FRONT_URL_PROD: "http://doacao.sec.ba.gov.br/",
};

export const ENVIRONMENTS: ENVIRONMENTS[] = [
  {
    ENVIRONMENT: "DEV",
    URL: "http://doacaodev.sec.ba.gov.br/",
    API: "http://doacaoapidev.sec.ba.gov.br/api",
  },
  {
    ENVIRONMENT: "HML",
    URL: "http://doacaohml.sec.ba.gov.br/",
    API: "http://doacaoapihml.sec.ba.gov.br/api",
  },
  {
    ENVIRONMENT: "PROD",
    URL: "https://bahiasemfome.educacao.ba.gov.br/",
    API: "https://bahiasemfomeapi.educacao.ba.gov.br/api",
  },
];

// export const API_TYPE: string = getApiType();
export const ONLINE_SYSTEM: boolean = true;
export const OFFLINE_PASSWORD: string = "S23kfXTuWT";
export const CACHE_PREFIX: string = "capfx3";
export const MOCK_DATA_RELEASED: boolean = false;
export const IS_ELECTORAL_PERIOD: boolean = false;
export const STEPS_ALLOWING_SPECIFIC_NAMES_FOR_TEAMS_AND_COMPETITORS = [
  "ESCOLAR",
];
export const CACHE_MINUTES: any = {
  GENERAL: 5,
  FILTERS: 60,
  MODALITY: 60,
  ALL_PROFILE_WITH_PERMITIONS: 60 * 2,
};

export default {
  ONLINE_SYSTEM,
  OFFLINE_PASSWORD,
  MOCK_DATA_RELEASED,
  CACHE_PREFIX,
  CACHE_MINUTES,
};

export const SYSTEM_MESSAGE = {
  ERROR_NO_INTERNET_CONNECTION:
    "Impossivel se conectar, verifique sua internet.",
  ERROR_TRY_LATER_LATER: "Isso não funcionou, tente novamente mais tarde.",
};

export const SYSTEM_GLOBAL_PARAMETERS = {
  DEFAULT_ROWS_PER_PAGE: window.screen.height <= 1000 ? 5 : 8,
};
