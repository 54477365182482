import Reactotron, { trackGlobalErrors } from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';

import api from 'services/api';
// import apisaucePlugin from 'reactotron-apisauce'; // <--- import

const reactotron = Reactotron.configure()

  // .use(apisaucePlugin())
  // .setAsyncStorageHandler(AsyncStorage)
  .use(reactotronRedux())
  .use(trackGlobalErrors())
  .connect();

console.tron = reactotron;
console.tron.log('asdasdsadas');
reactotron.clear();

// api.addMonitor(Reactotron.apisauce);
